<template>
    <div>
        <div class="list" style="padding: 10px;">
            <el-input style="width: 200px;" placeholder="根据数据类型查询" type="text" v-model="type" clearable @keydown.enter.native="getFileData"></el-input>
            <el-button @click="getFileData">查询</el-button>
            <el-table
                :data="fileList"
                style="width: 700px" 
                border 
                highlight-current-row 
                stripe 
                max-height="700"
                >
                <el-table-column
                  type="index"
                  label="序号" 
                  width="50" fixed>
                </el-table-column>
                <!-- <el-table-column
                    prop="path"
                    label="文件地址"
                    :show-overflow-tooltip="true"
                    align="center"
                    width="380">
                </el-table-column> -->
                <el-table-column
                    prop="name"
                    label="文件名称"
                    :show-overflow-tooltip="true"
                    align="center"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="文件类型"
                    :show-overflow-tooltip="true"
                    align="center"
                    width="250">
                </el-table-column>
                <el-table-column
                  fixed="right"
                  width="180"
                  align="center"
                  label="操作">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="deleteRow(scope.$index)"
                      type="text"
                      size="small">
                      删除
                    </el-button>
                    <el-button
                      @click.native.prevent="downFile(scope.$index)"
                      type="text"
                      size="small">
                      下载
                    </el-button>
                  </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="pageHeader">
            <el-pagination
                background
                layout="total,sizes,prev, pager, next,jumper"
                :total="total" 
                :page-sizes="[10, 20, 50, 100]"
                :page-size="10"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange">
            </el-pagination>
        </div>
    </div>
  </template>

  <script>
  import {get,post,getDownFile} from '@/utils/HttpHelper.js'
    export default {
      name:'File',
      data() {
        return {
          fileList: [],
          pageNo:1,
          pageSize:10,
          total:0,
          type:'png',
          status:''
        }
      },
      mounted(){
        var thisPagePath = this.$route.fullPath
        var dynamicaTags = JSON.parse(localStorage.getItem("dynamicTags"));
        
        var dynamicaTag = dynamicaTags.filter(item=>{
            return item.path === thisPagePath
        })
        if(dynamicaTag.length > 0){
          return
        }
        this.getFileData()
      },
      methods:{
        async getFileData(){
          let res = await post('/test/getFileList?isPc=1&pageNo='+this.pageNo+'&pageSize='+this.pageSize+'&type='+this.type+'&path=/opt/tomcat8/webapps/file/'+this.type)
            .then((res) => res.data).then((res) => {
                this.fileList = res.data
                this.total = res.total
           })
        },
        handleCurrentChange(val){
          this.pageNo=val
          this.getFileData()
        },
        handleSizeChange(val){
          this.pageSize = val
          this.getFileData()
        },
        deleteRow(index){
          var path = this.fileList[index].path;
          var name = this.fileList[index].name;
          this.$confirm('此操作将永久删除'+name+'文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.delFile(path,index);
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });          
          });
        },
        async delFile(path){
            let res = await get('/test/delSftpFile?path='+path) .then((res) => res.data).then((res) => {
                if(res){
                    // this.fileList.splice(index, 1); //删除
                    this.getFileData()
                }
            })
        },
        async downFile(index){
            var path = this.fileList[index].path;
            var name = this.fileList[index].name;
            var type = this.fileList[index].type;
            //以流的方式下载
            const data = await getDownFile('/test/downSftpFile?path='+path);
            if (window.navigator.msSaveOrOpenBlob) {
                // 兼容IE
                const blob = new Blob([data.data], { type: 'application/ms-excel;charset=utf-8' })
                navigator.msSaveBlob(blob, name)
            } else {
                // chrome/firefox
                const blob = new Blob([data.data], { type: 'application/ms-excel;charset=utf-8' })
                const downloadElement = document.createElement('a')
                const href = window.URL.createObjectURL(blob) // 创建下载的链接
                console.log(href)
                downloadElement.href = href
                downloadElement.download = name // 下载后文件名
                document.body.appendChild(downloadElement)
                downloadElement.click() // 点击下载
                document.body.removeChild(downloadElement) // 下载完成移除元素
                window.URL.revokeObjectURL(href) // 释放掉blob对象
            }
            //以链接的方式下载
            // const a = document.createElement('a'); // 创建一个HTML 元素
            // let res = 'https://dldir1.qq.com/qqfile/qq/PCQQ9.7.1/QQ9.7.1.28934.exe';
            // let res = 'http://114.116.245.137:8080/file/'+type+'/'+name;
            // console.log(res)
            // a.setAttribute('download', ''); //download属性
            // a.setAttribute('href', res); // href链接
            // a.click(); // 自执行点击事件
              
        },
        
      },
    }
  </script>
