<template>
	<div>
		<div id="chart3" style="width:500px;height:300px;" ref="echart"></div>
	</div>
</template>
 
<script>
	export default {
		name: 'Home',
		mounted() {
			let echarts = require('echarts');
			var option = {
				title: {
          text: 'ECharts示例'
        },
        tooltip: {},
        xAxis: {
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
			}
			this.$nextTick(() => {
          var charts = echarts.init(this.$refs.echart)
          charts.clear()
          charts.resize()
          charts.setOption(option)
			 })
			
		}
	}
</script>