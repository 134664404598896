<template>
    <div class="home" @click="dianjiHtml" >
      <div>
        <img v-show="isChecked" @click="XZImg(item.id)" :src="item.emoji_url" v-for="item in emojiList" :key="item.id" width="100px" height="100px">
      </div>
      <input type="text" placeholder="请输入消息" v-model="msg" class="inp"/>
      <button class="btn" @click="handleSendBtnClick">发送</button>
      是否选择表情包<input type="checkbox"  v-model="isChecked"  @change="handleCheckChange">
      <select  v-model="selectedItem">
        <option v-for="item in selectList" :key="item" :value='item.language'>{{item.languageName}}</option>
      </select>
      <span v-if='this.imgId != 0'>
        已选择表情包
          <img :src="imgSrc" width="100px" height="100px">
      </span>
      <br/><br/>
      <ul class="chatUl">
        <li v-for="(item,index) of msgList" :key="index">
          <span v-if="item.isShow">
            <p>{{ handelDate(item.dateTime) }}</p>
            <p v-if="item.type==null" @contextmenu.prevent="handleRightClick($event,item)" @mouseup="getSelectedData"><span>{{ item.user }}</span>：{{ item.msg }}
                <br/>{{item.translateData !== undefined?('翻译结果:'+item.translateData):''}}</p><br/>
            <p v-if="item.type!=null"><span>{{ item.user }}</span>：<img :src='item.msg' width="100px" height="100px"></p><br/>
          </span>
        </li>
      </ul> 
      <div id="context-chat">
        <ul>
          <li @click="translate">翻译</li>
          <li @click="copy">复制</li>
        </ul>
      </div>
    </div>
  </template>
   
  <script>
  import {ws} from '@/utils/chatWs.js'
  import {post,get} from '@/utils/HttpHelper.js'
   
  export default {
    name: "ChatTest",
    props:['msgList'],
    data() {
      return {
        msg: "",
        username: "admin",
        // msgList: [],数据从菜单过来，这里不再加载，这个页面负责发送信息，菜单处理信息返回到这个页面显示（在其他菜单也可以接收到信息）
        selectList: [],
        emojiList: [],
        selectedItem: '',
        izXZImg: false,
        imgId: 0,
        isChecked: false,
        selectData: '',
        translateIndex: '',
      };
    },
    mounted() {
      var user = JSON.parse(localStorage.getItem('user'))
      this.username = user.nickname
      this.getSelectList()
      this.getEmojiList()
    },
    
    computed: {
      imgSrc() {
        const item = this.emojiList.find(item => item.id === this.imgId);
        return item ? item.emoji_url : 'Not Found';
      }
    },
    methods: {
      translate(){
        var that = this;
        console.log('需要翻译的是',this.selectData)
        get('/test/translate?content='+this.selectData+"&language=zh") .then((res) => res.data).then((res) => {
          console.log('msgList',that.msgList,this.translateIndex)
          for(var i in that.msgList){
            if(that.msgList[i].id === that.translateIndex){
              that.msgList[i].translateData = res;
            }
          }
        })
      },
      copy(){
        console.log('需要复制的是',this.selectData)
        const textarea = document.createElement('textarea');
        textarea.value = this.selectData;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
      },
      getSelectedData(event) {
        let selectedData = window.getSelection().toString();
        this.selectData = selectedData
        console.log('鼠标所选数据:', this.selectData);
      },
      dianjiHtml(){
        //获取自定义上下文菜单元素
        var contextMenu = document.getElementById('context-chat');
        contextMenu.style.display = 'none';
        console.log('点击了取消');
        this.tagIndex = null;
    },
      // 定义处理右击事件的方法
      handleRightClick(event,item) {
        if(this.selectData === ''){
          return;
        }
        this.translateIndex = item.id;
        // 阻止默认的上下文菜单显示
        event.preventDefault();
        // 在这里添加你的逻辑，比如显示一个自定义的右键菜单
        console.log('鼠标右击事件被触发');
        // 你可以根据需求使用event对象，比如获取鼠标位置
        console.log(event.clientX, event.clientY);
        // 获取自定义上下文菜单元素
        var contextMenu = document.getElementById('context-chat');
        // 设置自定义上下文菜单的位置
        contextMenu.style.left = event.clientX + 'px';
        contextMenu.style.top = event.clientY + 'px';
        // 显示自定义上下文菜单
        contextMenu.style.display = 'block';
      },
      handleSendBtnClick() {
        const msg = this.msg;
        if (!msg.trim().length) {
          alert('请输入发送信息。')
          return;
        }
        if(this.isChecked){
          if(this.imgId == 0){
             alert('请选择表情包。')
            return;
          }
          this.getEmoJi(this.imgId,msg)
        }else{
          ws.send(
            JSON.stringify({
              id: new Date().getTime(),
              user: this.username,
              dateTime: new Date().getTime(),
              msg: this.msg,
              isShow: true,
              language: this.selectedItem,
            })
          );
    
          this.msg = "";
        }
      },
      handelDate(date){
        var now = new Date().getTime()
        var second = (now - date)/1000
        var message = ''
        if(second < 60){
          message = '刚刚'
        }else if(second>=60 && second < (60*60)){
          message = Math.floor(second/60)+' 分钟前'
        }else if(second>=(60*60) && second < (60*60*24)){
          message = Math.floor(second/60/60)+' 小时前'
        }else if(second>=(60*60*24)){
          message = Math.floor(second/60/60/24)+' 天前'
        }
        return message
      },
      
      getSelectList(){
        get('/test/getLanguageList') .then((res) => res.data).then((res) => {
          this.selectList = res
          console.log('接受到的language数据：'+JSON.stringify(res))
        })
      },
      getEmojiList(){
        get('/test/getEmoJiList?username='+this.username) .then((res) => res.data).then((res) => {
          this.emojiList = res
          console.log('接受到的emoJi数据：'+JSON.stringify(res))
        })
      },
      async getEmoJi(imgId,text){
        post('/test/getEmoJiGif?id='+imgId+'&text='+text) .then((res) => res.data).then((res) => {
          console.log('接受到的getEmoJi数据：'+JSON.stringify(res))
          this.msg = res
          
          ws.send(
            JSON.stringify({
              id: new Date().getTime(),
              type:'img',
              user: this.username,
              dateTime: new Date().getTime(),
              msg: res,
              isShow: true,
              language: this.selectedItem,
            })
          );
    
          this.msg = "";
        })
      },
      XZImg(id){
          this.imgId = id;
      },
      handleCheckChange(){
        this.getEmojiList()
        if(this.isChecked==false){
          this.imgId = 0;
        }
      },
    },
  };
  </script>
   
  <style scoped>
  .red {
    color: red;
  }
   
  .btn {
    width: 100px;
    /* height: 50px; */
    background-color: rgb(12, 248, 158);
  }
  .chatUl{
    padding: 10px;
    width: 500px;
    height: 500px;
    overflow: hidden;
		overflow-y: scroll;
	}
  /*设置滚动条*/
  .chatUl::-webkit-scrollbar{
      width:0px;
  }
.forbiddenText{
    margin-right: 10px;
  }
  #context-chat {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1000;
  }
  #context-chat ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #context-chat ul li {
    padding: 10px;
    color: fuchsia;
    cursor: pointer;
  }
  #context-chat ul li:hover {
    background-color: #ddd;
  }
  </style>