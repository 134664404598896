<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
        return {
          
        };
      },
  }
</script>

<style>
  .forbiddenText {
    -ms-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
</style>
