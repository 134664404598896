import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router';
import store from '@/store'
//去除vue自带样式
import '@/assets/style/reset.css';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(VueRouter)
Vue.config.productionTip = false
Vue.use(ElementUI);

// 拦截跳转的路由地址
router.beforeEach((to, from, next) => {
  var user = JSON.parse(localStorage.getItem("user"))
  if(to.path === '/login' && from.path != '/login'){
    next();
    return;
  }
  if(null != user && user.username != null && to.path != '/login'){
      var routerList = JSON.parse(sessionStorage.getItem("routerList"))
      var addFlag = routerList.filter(item=>{
        return item.path === to.path
      })
      console.log(to)
      if(addFlag.length  === 0 && from.path != '/login' && to.path != '/muen/home'){
        alert("您没有权限跳转到该页面")
        router.back()
        return
      }
      next();
  }else{
    next("/login");
  }
}),
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
