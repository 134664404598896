<template>
  <div>
      <div class="list" style="padding: 10px;">
          <el-input style="width: 200px;" placeholder="根据数据类型查询" type="text" v-model="type" clearable @keydown.enter.native="getFileData('btn')"></el-input>
          <el-button  @click="getFileData('btn')">查询</el-button>
          <el-table
              :data="fileList"
              style="width: 1600px" 
              border 
              highlight-current-row 
              stripe 
              max-height="700"
              >
              <el-table-column
                type="index"
                label="序号" 
                width="50" fixed>
              </el-table-column>
              <el-table-column
                  prop="id"
                  label="编号" 
                  width="80"
                  align="center"
                  fixed>
              </el-table-column>
              <el-table-column
                  prop="fileName"
                  label="文件名称"
                  :show-overflow-tooltip="true"
                  align="center"
                  width="280">
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="文件原名"
                  :show-overflow-tooltip="true"
                  align="center"
                  width="280">
              </el-table-column>
              <el-table-column
                  prop="fileAddressName"
                  label="文件服务器名称"
                  :show-overflow-tooltip="true"
                  align="center"
                  width="250">
              </el-table-column>
              <el-table-column
                  prop="width"
                  label="宽度"
                  align="center"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="height"
                  label="高度"
                  align="center"
                  width="180">
              </el-table-column>
              <el-table-column
                  prop="fileAddress"
                  label="文件地址"
                  :show-overflow-tooltip="true"
                  align="center"
                  width="580">
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="创建时间"
                  :show-overflow-tooltip="true"
                  align="center"
                  width="170">
              </el-table-column>
              <el-table-column
                fixed="right"
                width="280"
                align="center"
                label="操作">
                <template slot-scope="scope">
                  <el-button v-if="fileList[scope.$index].type === 'mp4' || fileList[scope.$index].type === 'mkv'" @click="playVideo(scope.$index,fileList)">
                    showVideo
                  </el-button>
                  <el-button v-if="fileList[scope.$index].type === 'png' || fileList[scope.$index].type === 'jpg' || fileList[scope.$index].type === 'gif'"
                   @click="showImgFun(scope.$index,fileList)">
                    showImg
                  </el-button>
                  <el-button v-if="fileList[scope.$index].type === 'mp3'" @click="showMp3Fun(scope.$index,fileList)">
                    showMp3
                  </el-button> 
                  <el-button
                    @click.native.prevent="deleteRow(scope.$index,fileList)">
                    delete
                  </el-button>
                </template>
              </el-table-column>
          </el-table>
      </div>
      <!-- 弹框：视频 -->
      <el-dialog class="ewm" title="" :visible.sync="showVideo" @close="pauseVideoPlay">
          <video   
            :src= "videoUrl"
            ref="Ref_videoPlayer"
            controls
            width="800" 
            height="500"
          ></video>
      </el-dialog>
      <!-- 弹框：图片 -->
      <el-dialog class="ewm" title="" :visible.sync="showImgFlag">
          <el-image
            style="width: 500px; height: 500px"
            :src="showImgUrl"
            fit="contain"></el-image>
      </el-dialog>
      <!-- 弹框：音频 -->
      <el-dialog class="audioC" title="" :visible.sync="showMp3Flag" @close="pauseAudioPlay">
          <Audio :musicAddress="showMp3Url" :musicName="showMp3Name" :showMp3Flag="showMp3Flag"/>
      </el-dialog>
      <!-- 分页 -->
      <div class="pageHeader">
          <el-pagination
              background
              layout="total,sizes,prev, pager, next,jumper"
              :total="total" 
              :page-sizes="[10, 20, 50, 100]"
              :page-size="10"
              @current-change="handleCurrentChange"
              :current-page="pageNo"
              @size-change="handleSizeChange">
          </el-pagination>
      </div>
  </div>
</template>

<script>
import {get} from '@/utils/HttpHelper.js'
import {del} from '@/utils/commonts.js'
import Audio from '@/pages/Audio'
import staticUrl from '@/utils/staticUrl.js'
  export default {
    name:'Table',
    components:{Audio},
    data() {
      return {
        fileList: [],
        total:0,
        pageNo:1,
        pageSize:10,
        type:'',
        showVideo:false,
        videoUrl:'',
        showImgFlag:false,
        showImgUrl:'',
        showMp3Flag:false,
        showMp3Url:'',
        showMp3Name:''
      }
    },
    mounted(){
        var thisPagePath = this.$route.fullPath
        var dynamicaTags = JSON.parse(localStorage.getItem("dynamicTags"));
        
        var dynamicaTag = dynamicaTags.filter(item=>{
            return item.path === thisPagePath
        })
        if(dynamicaTag.length > 0){
          return
        }
        this.getFileData(null)
    },
    methods:{
      playVideo(index,list){
        console.log(index,list)
        this.videoUrl = list[index].videoAddress
        console.log(this.videoUrl)
        this.showVideo = true
      },
      pauseVideoPlay(){
        this.$refs.Ref_videoPlayer.pause();
      },
      showImgFun(index,list){
        console.log(index,list)
        this.showImgUrl = list[index].fileAddress
        console.log(this.showImgUrl)
        this.showImgFlag = true
      },
      showMp3Fun(index,list){
        this.showMp3Url = list[index].fileAddress
        this.showMp3Name = list[index].fileName
        this.showMp3Flag = true
      },
      pauseAudioPlay(){
        this.showMp3Flag = false
        console.log("table----showMp3Flag:"+this.showMp3Flag)
      },
      getFileData(btn){
        if(null != btn){
          this.pageNo = 0;
        }
        get('/test/getFileData?isPc=1&type='+this.type+'&pageNo='+this.pageNo+'&pageSize='+this.pageSize) .then((res) => res.data).then((res) => {
          for(var i in res.data){
            if(res.data[i].type === 'mp4' || res.data[i].type === 'mkv'){
              res.data[i].fileAddress = res.data[i].videoAddress
            }
          }
          this.fileList = res.data
          this.total = res.total
        })
      },
      handleCurrentChange(val){
        this.pageNo=val
        this.getFileData()
      },
      handleSizeChange(val){
        this.pageSize = val
        // console.log(val)
        this.getFileData()
      },
      deleteRow(index,list){
        var path = list[index].fileAddress
        var type = list[index].type
        if(type === 'mp4' || type === 'mkv'){
          path =  list[index].videoAddress
        }
        path = path.replace(staticUrl,'');
        var id = list[index].id
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.fileList.splice(index, 1); //删除
          this.delSftpFile(path,id)
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      },
      delSftpFile(path,id){
        path = "/opt/tomcat8/webapps/"+path
        console.log(path,id)
        get('/test/delSftpFile?path='+path+'&id='+id)
      }
    },
  }
</script>
<style scoped>
 .pageHeader{
  width: 500px;
  padding-top:20px ;
  margin: auto;
 }
</style>
