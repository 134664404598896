<template>
  <el-upload
    class="upload-demo"
    ref="upload"
    :action="action"
    :on-preview="handlePreview"
    :on-remove="handleRemove"
    :file-list="fileList"
    :auto-upload="false" multiple>
    <el-button slot="trigger" size="small" type="primary">选取表情包</el-button>
    <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到表情包列表</el-button>
    <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadFiles">上传到表情包列表2</el-button>
  </el-upload>
</template>
<script>
  import baseURL from '@/utils/config';
  export default {
    data() {
      return {
        fileList: [],
        action: ''
      };
    },
    mounted() {
      var user = JSON.parse(localStorage.getItem('user'))
      this.action = baseURL+'/test/saveEmoJiFile?username='+user.nickname
    },
    methods: {
      submitUpload() {
        this.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file, this.fileList);
      },
       uploadFiles(event) {
        const files = event.target.files;
        for (let i = 0; i < files.length; i++) {
          this.saveFile(files[i]);
        }
      },
      saveFile(file){
        const formData = new FormData();
        formData.append('file', file);
        axios.post('http://jiuyao.love:9201/test/saveFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          // 处理响应数据
          console.log('多文件上传---',response.data);
        })
        .catch(error => {
          // 处理错误情况
          console.error(error);
        });
      }
      }
    }
</script>