<template>
    <div class="container">
      <el-upload
        class="upload-demo"
        drag
        :action="action"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
  </template>
  
  <script>
  import baseURL from '@/utils/config';
  export default {
    name:'UpLoadFile',
    data() {
      return {
        action:baseURL+'/test/saveFile'
      };
    },
    methods: {
      handlePreview(file) {
        console.log(file);
      },
      handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  