import Vuex from 'vuex'
import Vue from 'vue'
const actions={}
const mutations={
    setTestCode(state,value){
        state.testCode = value
        console.log('store获取了数据',state.testCode)
    }
}
const state={
    testCode:''
}
Vue.use(Vuex)
export default new Vuex.Store({
    actions,
    mutations,
    state
})