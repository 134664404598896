<template>
  <div>
    <!-- 上传文件，有返回数据<el-button slot="trigger" size="small" type="primary"><input type="file" @change="onFileChange" /></el-button>
    <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadFile">上传文件</el-button> -->
    <input type="text" v-model="address" placeholder="请输入视频地址">
    <el-button style="margin-left: 10px;" size="small" type="success" v-if="!addTimeShow" @click="addTimeShowClick">展示视频</el-button>
    <el-button style="margin-left: 10px;" size="small" type="success" v-if="addTimeShow" @click="addTimeShowClick">关闭视频</el-button>
    <div v-if="addTimeShow">
      <video :src="address" controls width="600px" height="300px"></video><br/>
      <el-button type="success"  @click="addTimeBox">添加时间段</el-button>
      <el-button style="margin-left: 10px;" size="small" type="success" @click="subVideo" v-if="showSubBtn">截取视频</el-button>
      <el-switch  v-if="showMerge" active-text="是否合并视频"
        v-model="izMerge"
        active-color="#ff4949"
        inactive-color="cadetblue">
      </el-switch>
      <div v-for="(item, index) in subVideoTimeStrList" :key="index">
        <el-time-picker v-model="item.startTimeStr" :picker-options="{selectableRange: '00:00:00 - 01:00:00'}"></el-time-picker> - 
        <el-time-picker v-model="item.endTimeStr" :picker-options="{selectableRange: '00:00:00 - 01:00:00'}"></el-time-picker>
        <el-button type="danger" icon="el-icon-delete" @click="removeTimeBox(index)"></el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {get,post} from '@/utils/HttpHelper'

export default {
  data() {
    return {
      selectedFile: null,
      address:'',
      videoPath:'',
      name:'',
      subVideoTimeStrList:[],
      addTimeShow: false,
      subVideoStr: [],
      izMerge:false,
      showSubBtn:false,
      showMerge:false,
    };
  },
  mounted(){
    
  },
  methods: {
      async getVideoData(videoAddress){
        
      },
      addTimeShowClick(){
        this.subVideoTimeStrList = []
        if(this.address === '' && !this.addTimeShow){
          this.$message({
              message: '请输入视频地址！',
              type: 'warning'
          });
          return;
        }
        var sqlStr = "select * from file_table where video_address = '"+this.address+"'";
        get('/test/getObjectData?sqlStr='+sqlStr).then((res) => res.data).then((res) => {
          var videoList = res;
          console.log("videoList.length",videoList)
          if(videoList.length === 0 || videoList === []){
            this.$message({
                message: '请上传正确的文件路径!',
                type: 'warning'
            });
            return;
          }
          // 判断视频是否存在数据库
          this.addTimeShow = !this.addTimeShow;
        });
      },
     subVideo(){
      var subVideoTimeStrList = this.subVideoTimeStrList;
      if(subVideoTimeStrList === [] || subVideoTimeStrList.length == 0){
        this.$message({
            message: '请新增时间段！',
            type: 'warning'
        });
        return;
      }
      var subVideoTime = {};
      // 大于1的时候才需要合并
      if(subVideoTimeStrList.length > 1){
        subVideoTime.izMerge = this.izMerge;
      } else {
        subVideoTime.izMerge = false;
      }
      subVideoTime.oldVideoPath = '/opt/tomcat8/webapps'+this.subName2(this.address);
      var list = [];
      for(var i in subVideoTimeStrList){
        if(subVideoTimeStrList[i].startTimeStr === '' || subVideoTimeStrList[i].endTimeStr === ''){
          this.$message({
            message: '时间段不能为空！',
            type: 'warning'
          });
          return;
        }
        var start = this.formatTimeToHHMMSS(subVideoTimeStrList[i].startTimeStr)
        var end = this.formatTimeToHHMMSS(subVideoTimeStrList[i].endTimeStr)
        console.log("time",start,end)
        list.push({ startTimeStr: start,endTimeStr:end});
      }
      subVideoTime.subVideoTimeStrList = list;
      console.log("subVideoTime",subVideoTime)
      post('/test/loadVideo',subVideoTime).then((res) => res.data).then((res) => {
        console.log("loadVideo----res",res)
        this.subVideoStr = res;
        this.addTimeShow = false;
      });
     },
     handelTime(){
        // 示例使用
        var dateTimeString = "2024-07-26T13:54:54.000Z";
        var formattedTime = this.formatTimeToHHMMSS(dateTimeString);
        console.log("formattedTime",formattedTime); // 输出: 13:54:54
     },
     formatTimeToHHMMSS(dateTimeString) {
        // 创建一个Date对象
        var date = new Date(dateTimeString);
        // 使用getHours(), getMinutes(), 和 getSeconds() 方法获取时间部分
        // 注意：getHours() 返回的是 0-23，getMinutes() 和 getSeconds() 返回的是 0-59
        // 我们使用模板字符串来格式化这个时间
        var hours = date.getHours();
        hours = this.addZeroStr(hours);
        var minutes = date.getMinutes();
        minutes = this.addZeroStr(minutes);
        var seconds = date.getSeconds();
        seconds = this.addZeroStr(seconds);
        var formattedTime = `${hours}:${minutes}:${seconds}`;
        return formattedTime;
    },
    addZeroStr(num){
      if(num < 10){
        return '0' + num
      }
      return num
    },
     addTimeBox() {
      this.showSubBtn = true;
      // 创建一个新的时间框对象，并加入数组
      // 注意：这里简单地使用了一个空字符串作为初始时间值
      this.subVideoTimeStrList.push({ startTimeStr: '',endTimeStr:'' });
      if(this.subVideoTimeStrList.length > 1){
        this.showMerge = true;
      }
    },
    // 删除指定索引的时间框的方法
    removeTimeBox(index) {
      // 使用splice方法从数组中移除指定索引的元素
      this.subVideoTimeStrList.splice(index, 1);
      if(this.subVideoTimeStrList.length === 0){
        this.showSubBtn = false;
      }
      if(this.subVideoTimeStrList.length < 2){
        this.showMerge = false;
      }
    },
    onFileChange(e) {
      // 获取文件对象
      this.selectedFile = e.target.files[0];
    },
    uploadFile() {
      if (!this.selectedFile) {
        this.$message({
            message: '请先选择文件！',
            type: 'warning'
          });
        return;
      }
      // 创建FormData对象
      const formData = new FormData();
      formData.append('file', this.selectedFile); // 'file'是后端期望的字段名
      post('/test/saveFile',formData).then((res) => res.data).then((res) => {
        console.log("res",res)
        this.address = res;
        this.name = this.subName(res);
        this.addTimeShow = true;
      });
    },
    subName(res){
       // 使用lastIndexOf()找到最后一个'is'的起始位置
        let lastIndex = res.lastIndexOf('/');
        // 如果'is'存在于字符串中
        if (lastIndex !== -1) {
            // 使用slice()从最后一个'is'之后的位置开始截取到字符串的末尾
           return res.slice(lastIndex+'/'.length);
        } 
        return '';
    },
    subName2(res){
       return res.replace('http://jiuyao.love:8080','')
    },
  },
};
</script>