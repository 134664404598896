import axiosInit from '@/utils/request'; //把上面的暴露出来的axionInit进行引入，这样就可以使用我们上面定义的拦截器
import baseURL from '@/utils/config';
import axios from 'axios';
import { Loading } from 'element-ui';

//封装get函数
// export const get = (url,data) => {
//     return axiosInit({
//         url:url,
//         method:'GET',
//         params:data,
//         headers:{
//             token:sessionStorage.getItem('token')
//         }
//     })
// }

//封装put函数
export const put = (url,data) => {
    return axiosInit({
        url:url,
        method:'PUT',
        data:data,
        headers:{
            token:sessionStorage.getItem('token')
        }
    })
}

//封装post函数
// export const post = (url,data) => {
//     return axiosInit({
//         url:url,
//         method:'POST',
//         data:data,
//         headers:{
//             token:sessionStorage.getItem('token')
//         }
//     })
// }
//封装get下载文件函数
export const get = (url,data)=>{
    console.log('get')
    console.log(baseURL+url)
    let loadingInstance = Loading.service();
    return new Promise((resolve,reject) => {
        axios({
            url: baseURL+url,// 请求地址
            method:'get',
            data,
            headers:{
                token:sessionStorage.getItem('token')
            }
        }).then(res => {  
            loadingInstance.close();
            resolve(res)
        }).catch(err=>{
            reject(err);
        });
    });
}
//封装get下载文件函数
export const post = (url,data)=>{
    console.log('post')
    console.log(baseURL+url)
    let loadingInstance = Loading.service();
    return new Promise((resolve,reject) => {
        axios({
            url: baseURL+url,// 请求地址
            method:'post',
            data,
            headers:{
                token:sessionStorage.getItem('token')
            }
        }).then(res => {  
            loadingInstance.close();
            resolve(res)
        }).catch(err=>{
            reject(err);
        });
    });
}

//封装get下载文件函数
export const getDownFile = (url)=>{
    let loadingInstance = Loading.service();
    return new Promise((resolve,reject) => {
        axios({
            url: baseURL+url,// 请求地址
            method: 'get',
            responseType: 'blob',
            headers:{
                token:sessionStorage.getItem('token')
            }
        }).then(res => {  
            loadingInstance.close();
            resolve(res)
        }).catch(err=>{
            reject(err);
        });
    });
}

//括号里面的url和data都是调用的时候传进来的参数
//我上面只是封装了常用的get、post、put请求方法，其他你们也可以自行封装。我这里不是采用默认导出的，因为需要什么就进行按需导入。如果默认导出的话，还得导出名字.post，个人不是很喜欢这种使用方法。

//封装get下载文件函数
export const post2 = (url,data)=>{
    console.log('post2','http://jiuyao.love:9209'+url)
    let loadingInstance = Loading.service();
    return new Promise((resolve,reject) => {
        axios({
            url: 'http://jiuyao.love:9209/'+url,// 请求地址
            method:'post',
            data,
        }).then(res => {  
            console.log('post2----res',res)
            loadingInstance.close();
            resolve(res)
        }).catch(err=>{
            reject(err);
        });
    });
}
export const get2 = (url,data)=>{
    console.log('get2')
    console.log(baseURL+url)
    let loadingInstance = Loading.service();
    return new Promise((resolve,reject) => {
        axios({
            url: 'http://jiuyao.love:9209/'+url,// 请求地址
            method:'get',
            data,
        }).then(res => {  
            loadingInstance.close();
            resolve(res)
        }).catch(err=>{
            reject(err);
        });
    });
}