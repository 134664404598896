import VueRouter from "vue-router";
import Home from '@/pages/Home'
import Muen from '@/pages/Muen'
import DemoHeader from '@/pages/DemoHeader'
import Login from '@/pages/Login'
import Table from '@/pages/Table'
import Test from '@/pages/Test'
import File from '@/pages/File'
import Code from '@/pages/Code'
import ChatTest from '@/pages/ChatTest'
import Audio from '@/pages/Audio'
import UpLoadFile from '@/pages/UpLoadFile'
import EmoJiUpload from '@/pages/EmoJiUpload'
import VideoSub from '@/pages/VideoSub'
export default new VueRouter({
    routes:[
        {path:'/', redirect: '/login'}, 
        {path:'/login',name:'登录',component: Login},
        {path:'/code',name:'获取验证码',component: Code},
        {
            path:'/muen',
            name:'菜单',
            component: Muen,
            children:[
                {path:'home',name:'首页',component: Home},
                {path:'table',name:'列表',component: Table},
                {path:'test',name:'测试页面',component: Test},
                {path:'file',name:'文件管理',component: File},
                {path:'chatTest',name:'聊天测试',component: ChatTest},
                {path:'audio',name:'音频',component: Audio},
                {path:'upLoadFile',name:'文件上传',component: UpLoadFile},
                {path:'emojiUpload',name:'文件上传',component: EmoJiUpload},
                {path:'videoSub',name:'视频剪辑',component: VideoSub},
            ]
        },
        {path:'/demoHeader',name:'demo头部',component: DemoHeader},
    ]
})
//处理重复跳转路由问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
