<template>
    <div class="bodyMain" :style="{ height: windowHeight + 'px',width: windowWidth + 'px'}">
        <div style="width: 100px;height:250px;"></div>
        <div style="width: 400px; background-color: rgba(200,200,200,0.4); margin:auto;padding:60px;">
            <el-form ref="form" :model="form" label-width="80px" @keydown.enter.native="onSubmit">
                <el-form-item>
                    <div style="font-size:30px;margin-left:40px;color:blue;">DEMO</div>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input type="text" @change="showCode(form.name)" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                    <el-input type="password" v-model="form.password"></el-input>
                </el-form-item>
                
                <el-form-item>
                    <div style="width:200px;  display: inline-block;"><el-input v-model="form.code" placeholder="请输入验证码"></el-input></div>
                    <div style="width:100px;  display: inline-block;"><Code :getTestCode="getTestCode"/></div>
                    
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">登录</el-button>
                </el-form-item>
            </el-form>


            
            <!-- <el-button type="text" @click="dialogTableVisible = true" style="margin-right: 20px;margin-left: 50px;">列表</el-button> -->
            <!-- 弹框 -->
            <el-dialog title="收货地址" :visible.sync="dialogTableVisible">
                <el-table :data="gridData">
                    <el-table-column property="date" label="日期" width="150"></el-table-column>
                    <el-table-column property="name" label="姓名" width="200"></el-table-column>
                    <el-table-column property="address" label="地址"></el-table-column>
                </el-table>
            </el-dialog>
            

            <!-- <el-button type="text" @click="dialogFormVisible = true">扫码登录</el-button> -->

            <el-dialog class="ewm" title="" :visible.sync="dialogFormVisible">
                <img :src="qrImgSrc">
            </el-dialog>
        </div>
    </div>
  
</template>

<script>
    import {get,post} from '@/utils/HttpHelper'
    import Code from '@/pages/Code'
    import { connectWebsocket, closeWebsocket } from '@/utils/ws'
    
    // connectWebsocket(
    //     'ws://127.0.0.1:9200/my-websocket',
    //     {"userName":"管理员","userId":1,"content":"的风格","type":0,
    //     "poster":"http://114.116.245.137:8080/file/jpg/8bfc2848acdd49bbb1503aef21db7882.jpg"},
    //     (data) => {
    //         console.log('成功的回调函数, 接收到的data数据: ', data)
    //     },
    //     (err) => {
    //         console.log('失败的回调函数', err)
    //     }
    // )
    export default {
        data() {
            return {
                qrImgSrc:'',
                content:'http://jiuyao.love',
                form: {
                    name: '',
                    password:'',
                    region: '',
                    code:'',
                },
                gridData: [
                    {
                        date: '2016-05-02',
                        name: '王小虎',
                        address: '上海市普陀区金沙江路 1518 弄'
                    }, {
                        date: '2016-05-02',
                        name: '六大幅',
                        address: '上海市普陀区金沙江路 1518 弄'
                    }, 
                ],
                formLabelWidth: '120px',
                dialogTableVisible: false,
                dialogFormVisible: false,
                windowWidth:'',
                windowHeight:'',
            }
        },
        components:{
            Code
        },
        mounted(){
            var that = this;
        //刚进入页面时，获取窗口默认宽高度
            this.windowWidth = window.innerWidth - 20
            this.windowHeight = window.innerHeight- 20
            console.log(this.windowWidth)
            console.log(this.windowHeight)
            that.getLoginQr()
            localStorage.setItem("user",JSON.stringify({}))
            console.log(localStorage.getItem("user"))
        },
        methods: {
            async getRouterList(){
                await get('/test/getRouterList') .then((res) => res.data).then((res) => {
                    this.routeList = res
                    sessionStorage.setItem("routerList",JSON.stringify(res))
                    this.$router.push({
                        path:'/muen'
                    })
                })
            },
            async getLoginQr(){
                if(this.content != ''){
                     var res = await get('/test/getLoginQr?content='+this.content);
                     this.qrImgSrc=res.data
                }
            },
            showCode(val){
                this.form.code = this.$store.state.testCode
            },
            async onSubmit() {
                var storeTestCode = this.$store.state.testCode
                if(this.form.name.trim() === ''){
                    this.$message({
                        message: '用户名不能为空!',
                        type:'warning'
                    });
                    return
                }
                if(this.form.password.trim() === ''){
                    this.$message({
                        message: '密码不能为空!',
                        type: 'warning'
                    });
                    return
                }
                if(this.form.code === ''){
                    this.$message({
                        message: '请输入验证码!',
                        type: 'warning'
                    });
                    return
                }
                if(storeTestCode.toLowerCase() != this.form.code.toLowerCase()){
                    this.$message({
                        message: '验证码不正确!',
                        type: 'warning'
                    });
                    return
                }
                var res = await post('/test/login?username='+this.form.name+'&password='+this.form.password);
                console.log(res.data)
                if(res.data != ''){
                    this.login(res)
                }else{
                    this.$message({
                        message: '账号或者密码错误！',
                        type: 'error'
                    });
                }
            },
            login(res){
                this.$message({
                    message: '登录成功',
                    type: 'success'
                });
                localStorage.setItem('token',res.data.token)
                localStorage.setItem('user',JSON.stringify(res.data))
                localStorage.setItem("dynamicTags",JSON.stringify([]))
                this.getRouterList()
            },
            onRegister(){
                if(this.form.name.trim() === ''){
                    this.$message({
                        message: '用户名不能为空!',
                        type:'warning'
                    });
                    return
                }
                if(this.form.password.trim() === ''){
                    this.$message({
                        message: '密码不能为空!',
                        type: 'warning'
                    });
                    return
                }
                this.$prompt('请输入注册码', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({ value }) => {
                    this.getRegister(value,this.form.name,this.form.password);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });       
                });
            },
            async getRegister(value,username,password){
                var res = await post('test/getRegister?registerCode='+value+'&username='+username+'&password='+password);
                console.log("res",res)
                if(res.data != null && res.data.username != null){
                    this.$message({
                        type: 'succcess',
                        message: '注册成功,正在登录...'
                    });
                    let lsTimer = setInterval(()=>{
                        clearInterval(lsTimer);
                        this.login(res)
                    },2000)
                }else{
                    if(res.data === ''){
                        this.$message({
                            type: 'info',
                            message: '用户名已存在'
                        }); 
                    }else{
                        this.$message({
                            type: 'info',
                            message: '注册码不存在'
                        });  
                    }
                }
            },
            getTestCode(val){
                console.log("test-getTestCode---"+val)
                this.form.testCode=val
            },
        }
    }
</script>

<style>
.bodyMain{
    background-image: url('@/assets/imger/pcImg.png');
}
.ewm{
    text-align: center;
}
</style>