<template>
  <div>
    <!-- <input type="file" multiple @change="handlePreview" />
    <el-button style="margin-left: 10px;" size="small" type="success" @click="uploadFiles">上传</el-button> -->
    <el-button style="margin-left: 10px;" size="small" type="success" @click="tk">弹框</el-button>

    <el-dialog
    title="提示"
    :visible.sync="tkShow"
    width="30%">
    </el-dialog>
  </div>
</template>
 
<script>
import axios from 'axios';
 
export default {
  data() {
      return {
        fileList: [],
        action: '',
        tkShow: true,
      };
    },
  methods: {
    tk(){
      this.tkShow = true
      console.log(this.tkShow)
    },
    handlePreview(event) {
      this.fileList = event.target.files
      console.log(this.fileList);
    },
    uploadFiles(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        this.saveFile(files[i]);
      }
    },
    saveFile(file){
      const formData = new FormData();
      formData.append('file', file);
      axios.post('http://jiuyao.love:9201/test/saveFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        // 处理响应数据
        console.log('多文件上传---',response.data);
      })
      .catch(error => {
        // 处理错误情况
        console.error(error);
      });
    }
  }
};
</script>