<template>
  <div>
    左侧菜单栏
  </div>
</template>

<script>
    export default {

    }
</script>

<style>

</style>