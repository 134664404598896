<template>
  <div id="main" @click="dianjiHtml">   
    <div class="topMuen">
        <div style="float: right;" index="45">
          <el-popconfirm
            confirm-button-text='退出登录'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            title="确定退出登录吗？"
            @confirm="clickImgOk"
          >
          <el-avatar slot="reference" :size="55" :src="avater"></el-avatar>
          </el-popconfirm>
        </div>
      </el-menu>
    </div>
   <el-container class="muen_bottom">
    <el-aside>
        <el-row class="tac">
          <el-col :span="12" style="width: 100%;">
            <h5>DEMO</h5>
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose">
              <el-submenu v-for="item in routeList" :index="item.id" :key="item.id" >
                <template slot="title">
                  <i class="el-icon-location"></i>
                  <span @click="addTag(item)">{{ item.name }}</span>
                </template>
              </el-submenu>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-container>
        <el-header>
          <el-tag class="forbiddenText" :color="(thisPagePath===tag.path)?'':'white'"
            :key="tag.path" v-for="tag in dynamicTags" 
            :closable="tag.status == null?false:true"
            :disable-transitions="false"
            @close="tagClose(tag)"
            @click="toPage(tag)">
            <span @contextmenu.prevent="handleRightClick($event,tag)">{{tag.name}}</span> 
          </el-tag>
        </el-header>
        <keep-alive include="File,Table">
          <router-view :msgList="msgList"></router-view>
        </keep-alive>
        <el-empty v-show="thisPagePath === '/muen'" description="暂无数据"></el-empty>
      </el-container>
   </el-container>
    <div id="context-menu">
      <ul>
        <li @click="closeTagLeft">关闭左侧</li>
        <li @click="closeTagRight">关闭右侧</li>
        <li @click="closeTag">关闭所有</li>
      </ul>
    </div>
  </div>
</template>

<script>
    import {ws} from '@/utils/chatWs.js'
    export default {
      data() {
        return {
          activeIndex: '1',
          activeIndex2: '1',
          dynamicTags: [
          ],
          routeList: [

          ],
          thisPagePath:'/muen',
          avater:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
          msgList: [],
          userName:'',
          tagIndex: null,
          num:0,
        };
      },
      mounted(){
        ws.addEventListener("message", this.handleWsMessage.bind(this), false);
        var dynamicaTags = JSON.parse(localStorage.getItem("dynamicTags"));
        if(dynamicaTags != null && dynamicaTags != ''){
          this.dynamicTags = JSON.parse(localStorage.getItem("dynamicTags"))
        }
        this.thisPagePath = this.$route.fullPath
        var user = JSON.parse(localStorage.getItem('user'))
        this.userName = user.nickname
        this.avater = user.avater
        this.getRouterList()
        var chatList = JSON.parse(sessionStorage.getItem("chatList"));
        if(null != chatList){
          for(var i=0;i<chatList.length;i++){
            this.msgList.push(JSON.parse(JSON.stringify(chatList[i])))
          }
        }
        //定时任务刷新信息时间
        setInterval(function(){ 
          ws.send(
            JSON.stringify({
              isShow: false
            })
          );
        },30000)
      },
      methods: {
        closeTagRight(){
          if(this.tagIndex === null){
            this.closeTag();
            return;
          }
          this.dynamicTags = this.dynamicTags.filter(item => (item.num === 1 || item.num <= this.tagIndex));
          this.loadLocalStoreDynamicTags(this.dynamicTags);
          var contextMenu = document.getElementById('context-menu');
          contextMenu.style.display = 'none';
          this.toPage(this.dynamicTags[0])
        },
        closeTagLeft(){
          if(this.tagIndex === null){
            this.closeTag();
            return;
          }
          this.dynamicTags = this.dynamicTags.filter(item => (item.num === 1 || item.num >= this.tagIndex));
          this.loadLocalStoreDynamicTags(this.dynamicTags);
          var contextMenu = document.getElementById('context-menu');
          contextMenu.style.display = 'none';
          this.toPage(this.dynamicTags[0])
        },
        closeTag(){
          this.dynamicTags = this.dynamicTags.filter(item => (item.num === 1));
          this.loadLocalStoreDynamicTags(this.dynamicTags);
          var contextMenu = document.getElementById('context-menu');
          contextMenu.style.display = 'none';
          this.toPage(this.dynamicTags[0])
        },
         dianjiHtml(){
            //获取自定义上下文菜单元素
            var contextMenu = document.getElementById('context-menu');
            contextMenu.style.display = 'none';
            console.log('点击了取消');
            this.tagIndex = null;
        },
         // 定义处理右击事件的方法
        handleRightClick(event,tag) {
          if(null !== tag.num){
            this.tagIndex = tag.num
          }else{
            this.tagIndex = null
          }
          console.log("tag",tag.num)
          // 阻止默认的上下文菜单显示
          event.preventDefault();
          // 在这里添加你的逻辑，比如显示一个自定义的右键菜单
          console.log('鼠标右击事件被触发',tag.num);
          // 你可以根据需求使用event对象，比如获取鼠标位置
          console.log(event.clientX, event.clientY);
          // 获取自定义上下文菜单元素
          var contextMenu = document.getElementById('context-menu');
          // 设置自定义上下文菜单的位置
          contextMenu.style.left = event.clientX + 'px';
          contextMenu.style.top = event.clientY + 'px';
          // 显示自定义上下文菜单
          contextMenu.style.display = 'block';
        },
        notificationMessage(userName,message) {
          const h = this.$createElement;
          this.$notify({
            title: userName,
            message: h('i', { style: 'color: teal'}, message),
            duration: 60000 //不会自动关闭设置为0
          });
        },
        handleWsMessage(e) {
          let msg = JSON.parse(e.data);
          this.msgList.unshift(msg);
          if(this.userName != msg.user && msg.isShow){
            this.notificationMessage(msg.user,msg.msg)
          }
        },
        async getRouterList(){
            var json = JSON.parse(sessionStorage.getItem("routerList"))
            this.routeList.push({id:0,name:'首页',path:'/muen/home'})
            for(var i = 0;i<json.length;i++){
              this.routeList.push(json[i])
            }
            if(this.routeList.length > 0){
              if(this.dynamicTags.length===0){
                this.thisPagePath=this.routeList[0].path
              }
              this.toPageTag(this.thisPagePath)
            }
        },
        handleOpen(key, keyPath) {
          console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
          console.log(key, keyPath);
        },
        handleSelect(key, keyPath) {
          console.log(key, keyPath);
        },
        //跳转页面---当前页直接返回
        toPage(tag){
          if(this.thisPagePath === tag.path){
            return
          }
          this.thisPagePath = tag.path
          this.toPageTag(tag.path)
        },
        //关闭页面---保留一个页面（报错）
        tagClose(tag){
          if(this.dynamicTags.length === 1){
            return 
          }
          this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
          this.loadLocalStoreDynamicTags(this.dynamicTags);
          if(tag.path === this.thisPagePath){
            var path = this.dynamicTags[this.dynamicTags.length-1].path;
            this.toPageTag(path)
          }
        },
        //跳转页面
        toPageTag(path){
          this.$router.push({
              path
          })
          this.thisPagePath = path
        },
        //加Tag标签
        addTag(tag){
          this.num = this.num +1
          tag.num = this.num
          var addFlag = this.dynamicTags.filter(item=>{
            return item.path === tag.path
          })
          if(addFlag.length  === 0){
            this.dynamicTags.push(tag);
            this.loadLocalStoreDynamicTags(this.dynamicTags);
          }
          //在跑页面
          this.toPage(tag)
        },
        //路由数据加载到本地缓存中
        async loadLocalStoreDynamicTags(dynamicTags){
          localStorage.setItem("dynamicTags",JSON.stringify(dynamicTags));
        },
        clickImgOk(){
          localStorage.removeItem('dynamicTags')
          localStorage.removeItem('routerList')
          sessionStorage.removeItem("chatList")
          this.$router.push({
            path:'/login'
          })
        }
      },
      //监听路由变化
      watch:{
        $route(to,from){
          this.addTag({path:to.path,name:to.name,status:to.status});
        },
        msgList(to,form){
          var list = JSON.stringify(to);
          sessionStorage.setItem("chatList",list)
        }
      }
    }
</script>

<style scoped>
  .forbiddenText{
    margin-right: 10px;
  }
  #context-menu {
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1000;
  }
  #context-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  #context-menu ul li {
    padding: 10px;
    color: fuchsia;
    cursor: pointer;
  }
  #context-menu ul li:hover {
    background-color: #ddd;
  }
</style>